import * as React from 'react';
import { Link } from 'gatsby';
import { SEO } from '../components/SEO';

const NotFoundPage = () => {
  return (
    <div>
      <SEO title='Not Found' />
      <h1>Page not found</h1>
      <p>😔 Oops...I guess there's nothing here.</p>
      {process.env.NODE_ENV === 'development' ? (
        <>
          <br />
          Try creating a page in <code>src/pages/</code>.
          <br />
        </>
      ) : null}
      <Link to='/'>Go home</Link>.
    </div>
  );
};

export default NotFoundPage;
